<template>
  <div class="flex_sp" style="width: 100%">
    <div class="three" v-for="item in data.floor" :key="item.id" :style="{ backgroundImage: `url(${item.bj_img})` }">
      <div class="tile_wrap">
        <div class="left">
          <img class="img" :src="item.icon" alt="" />
        </div>
        <div class="right c_p" @click="handleJump(item.jump)">
          <span style="font-size: 16px; cursor: pointer">更多</span>
          <div class="more">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="top_img">
          <img :src="item.adv.image" alt="" />
        </div>
        <div class="goods_box">
          <div class="goods" v-for="sub in item.goodsArr" :key="sub.id" @click="handleDetail(sub.id)">
            <div class="good_img">
              <img :src="sub.image.url" alt="" />
            </div>
            <p class="pr">
              ￥{{ sub.price }}
              <span v-if="sub.price1 > 0" style="text-decoration: line-through; color: rgb(116, 113, 113); font-size: 12px">{{ sub.price1 }}</span>
            </p>
            <tagcom :tags="sub.tags"></tagcom>
            <p class="til shenglue_1">{{ sub.title }}</p>
            <p class="shenglue_1 f12">{{ sub.specification }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
import tagcom from "@/components/tag.vue";
export default {
  components: {
    tagcom,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
    };
  },
  methods: {
    handleDetail(id) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.three {
  width: 393px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  background-size: contain;
  .tile_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 120px !important;
        height: 29px !important;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .main {
    padding: 0 20px;
    padding-bottom: 10px;
    .top_img {
      width: 100%;
      height: 210px;
      background-color: #eee;
      border-radius: 4px;
      cursor: pointer;
    }
    .goods_box {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .goods {
        padding: 10px;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.8);
        }
        .good_img {
          width: 135px;
          height: 135px;
          background: #eeeeee;
          border-radius: 4px;
        }
        .pr {
          font-size: 16px;
          font-weight: bold;
          color: @priceRed;
          margin-top: 4px;
        }
        .til {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          margin: 4px 0;
        }
      }
    }
  }
}
</style>
