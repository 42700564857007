<template>
  <div class="flex_sp" style="width: 100%">
    <div class="one" v-for="item in data.floor" :key="item.id" :style="{ backgroundImage: `url(${item.bj_img})` }">
      <div class="tile_wrap">
        <div class="left">
          <img class="img" :src="item.icon" alt="" />
        </div>
        <div class="right" @click="handleMore(item)">
          <span style="font-size: 16px; cursor: pointer">更多</span>
          <div class="more">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="img_good">
          <img :src="item.adv.image" alt="" />
        </div>
        <div class="good_wrap">
          <div class="item" v-for="sub in item.goodsArr" :key="sub.id" @click="handleDetail(sub.id)">
            <div class="img">
              <img :src="sub.image.url" alt="" />
            </div>
            <div class="info">
              <p class="price">
                ￥{{ sub.price }}
                <span v-if="sub.price1 > 0" style="text-decoration: line-through; color: rgb(116, 113, 113); font-size: 12px">{{ sub.price1 }}</span>
              </p>
              <!-- <tagcom :tags="sub.tags"></tagcom> -->
              <p class="shenglue_1">{{ sub.title }}</p>
              <p class="pr shenglue_1">{{ sub.specification }}</p>
              <p class="pr shenglue_2">{{ sub.manufacturer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
// import tagcom from "@/components/tag.vue";
export default {
  // components: {
  //   tagcom,
  // },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
    };
  },
  methods: {
    handleDetail(id) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id },
      });
      window.open(href, "_blank");
    },
    handleMore(item) {
      console.log("首页---", item);
      // this.$store.commit("CHANGE_PAGENAME", ["首页", item.title]);
      if (item.jump.url == "/index/allGoods") {
        this.$store.commit("CHANGE_TAB", 1);
      }
      handleJump(item.jump);
    },
  },
};
</script>

<style lang="less" scoped>
.one {
  width: 595px;
  height: 417px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  background-size: contain;
  .tile_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 120px !important;
        height: 29px !important;
      }
      span {
        font-size: 24px;
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        color: #48c73c;
      }
    }
    .right {
      display: flex;
      align-items: center;
      cursor: pointer;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .main {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    .img_good {
      width: 266px;
      height: 330px;
      cursor: pointer;
    }
    .good_wrap {
      flex: 1;
      margin-left: 10px;
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        &:last-child {
          margin: 0;
        }
        &:hover {
          box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.8);
        }
      }
      .img {
        width: 100px;
        height: 100px;
        background-color: #eee;
        margin-right: 15px;
      }
      .info {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        .pr {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          margin-top: 6px;
        }
        .price {
          color: @priceRed;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
