<template>
  <div class="goods_cate">
    <div class="content" @mouseleave="show = false">
      <div @click="toGoods(item)" class="cate_wraps" v-for="(item, i) in cateList" :key="i" @mouseenter="mouseenterOut(i, item)" :class="[i === currentOutId ? 'active' : '']">
        <div class="img">
          <img :src="item.image" alt="" />
        </div>
        <span class="shenglue_1 flex_1" style="margin-right: 10px">{{ item.title }}</span>
      </div>
      <div class="detail" v-show="show" @mouseenter="mouseenterInner" @mouseleave="show = false">
        <div class="inner" v-for="item in currentItem.children" :key="item.title">
          <p class="til">{{ item.title }}</p>
          <div class="item_item">
            <div class="flex_row" @mouseenter="mouseenterItem(sub.id)" v-for="sub in item.children" :key="sub.id" :class="[sub.id === currentId ? 'active' : '']" @click="goto(sub)">
              <p class="p1">{{ sub.title }}</p>
              <p class="p1">|</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
export default {
  data() {
    return {
      cateList: [],
      currentItem: {},
      show: false,
      currentOutId: "",
      currentId: "",
    };
  },
  watch: {
    show(val) {
      if (!val) {
        this.currentOutId = "";
        this.currentId = "";
      }
    },
  },
  created() {
    this.$api("home.getCate").then((res) => {
      this.cateList = res.data;
    });
  },
  methods: {
    toGoods(item){
      console.log("选中的分类-----", item);
      this.$store.commit("CHANGE_PAGENAME", ["分类", item.title]);
      // if (item.url.url == "/index/allGoods") {
      //   this.$store.commit("CHANGE_TAB", 1);
      // }
      // handleJump(item.url);
      this.$store.commit("CHANGE_TAB", 1);
      
      // let url = `/index/allGoods?cate_id=${item.id}`
      let jump={
        type:101,
        value:`cate_id=${item.id}`,
        url:"/index/allGoods"
      }
      handleJump(jump)
      
      this.$emit("change", true);
    },
    goto(item) {
      // console.log("选中的分类-----", item);
      this.$store.commit("CHANGE_PAGENAME", ["分类", item.title]);
      if (item.url.url == "/index/allGoods") {
        this.$store.commit("CHANGE_TAB", 1);
      }
      handleJump(item.url);
      this.$emit("change", true);
    },
    mouseenterOut(i, item) {
      this.currentOutId = i;
      this.currentItem = item;
      this.show = true;
    },
    mouseenterInner() {
      this.show = true;
    },
    mouseenterItem(i) {
      this.currentId = i;
    },
  },
};
</script>

<style lang="less" scoped>
.goods_cate {
  z-index: 999;
  width: 200px;
  height: 480px;
  background-color: #fff;
  position: relative;
  .content {
    overflow: scroll;
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    .cate_wraps {
      padding-left: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 40px;
      flex-shrink: 0;
      &:hover {
        background-color: @themeColor;
        color: #fff;
      }
      &.active {
        background-color: @themeColor;
        color: #fff;
      }
      .img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
      span {
      }
    }
  }
  .detail {
    position: absolute;
    left: 200px;
    width: 540px;
    height: 100%;
    background-color: #fff;
    border: 1px solid @themeColor;
    overflow: hidden;
    overflow-y: auto;
    .inner {
      padding: 20px;
      .til {
        font-weight: bold;
        color: #000;
        margin-bottom: 8px;
      }
      .item_item {
        display: flex;
        cursor: pointer;
        flex-wrap: wrap;
        .p1 {
          font-size: 14px;
          font-weight: 400;
          color: #666;
          margin-right: 15px;
          line-height: 2;
        }
        .active .p1 {
          color: @themeColor;
        }
      }
    }
  }
}
</style>
