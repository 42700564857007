import { render, staticRenderFns } from "./backdrop.vue?vue&type=template&id=cbc2a660&scoped=true&"
import script from "./backdrop.vue?vue&type=script&lang=js&"
export * from "./backdrop.vue?vue&type=script&lang=js&"
import style0 from "./backdrop.vue?vue&type=style&index=0&id=cbc2a660&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbc2a660",
  null
  
)

export default component.exports